export const SUBSCRIPTION_PACKAGES = [
    {
        title: 'Daně a účetnictví',
        key: 'taxes',
        moduleId: 'taxes',
        color: '#F2941A',
        saleBoxBackground: '#FAEFE1',
        icon: 'D',
        benefits: [
            '<span class="bold">80+</span> nových webinářů každý rok',
            '<span class="bold">TOP lektoři</span> zodpovídají vaše dotazy',
            'Archiv všech <span class="bold">videozáznamů</span>',
            '<span class="bold">Školicí materiály</span> ke stažení',
            '1 licence <span class="bold">pro 5 osob</span>',
            'Exkluzivní webináře navíc',
        ],
        price: '14 157',
    },
    {
        title: 'Mzdy, pracovní právo a personalistika',
        key: 'wages',
        moduleId: 'wages',
        color: '#F00035',
        saleBoxBackground: '#F7DADA',
        icon: 'M',
        benefits: [
            '<span class="bold">80+</span> nových webinářů každý rok',
            '<span class="bold">TOP lektoři</span> zodpovídají vaše dotazy',
            'Archiv všech <span class="bold">videozáznamů</span>',
            '<span class="bold">Školicí materiály</span> ke stažení',
            '1 licence <span class="bold">pro 5 osob</span>',
            'Exkluzivní webináře navíc',
        ],
        price: '14 157',
    },
    {
        title: 'Veřejná správa',
        key: 'government',
        moduleId: 'government',
        color: '#63BF4D',
        saleBoxBackground: '#E7F5E4',
        icon: 'V',
        benefits: [
            '<span class="bold">30+</span> nových webinářů každý rok',
            '<span class="bold">TOP lektoři</span> zodpovídají vaše dotazy',
            'Archiv všech <span class="bold">videozáznamů</span>',
            '<span class="bold">Školicí materiály</span> ke stažení',
            '1 licence <span class="bold">pro 5 osob</span>',
            'Exkluzivní webináře navíc',
        ],
        price: '8 349',
    },
];

export const SUBSCRIPTION_PACKAGES_KEYS = ['taxes', 'wages', 'government'];

export const SUBSCRIPTION_PACKAGES_KEYS_ENUM = {
    TAXES: 'taxes',
    WAGES: 'wages',
    GOVERNMENT: 'government',
};
