import React, { FC, useState } from 'react';
import { Icons } from '@components/Icon';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import { handleDownloadClick, handleDownloadClickAsZip } from '@utils/material.utils';
import { ArrowIcon, FileIcon, Header, Label, Material, Materials, Name, Wrapper } from './MyMaterials.elements';

export interface MyMaterialsProps {
    materials: { name: string; filename: string; _key: string; mime_type: string }[];
    eventSlug: string;
}

const MyMaterialsTemplate: FC<MyMaterialsProps> = ({ materials, eventSlug }) => {
    const { t } = useTranslations();

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Wrapper>
            <Materials isOpen>
                <Header>
                    <Label onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? t('material.close.all') : t('material.show.all')}
                    </Label>
                    <ArrowIcon
                        onClick={() => setIsOpen(!isOpen)}
                        className={isOpen ? 'isOpen' : 'isClose'}
                        name={Icons.ArrowDown}
                    />
                    <ButtonTemplate
                        onClick={async () => {
                            setIsLoading(true);
                            await handleDownloadClickAsZip(materials, eventSlug);
                            setIsLoading(false);
                        }}
                        text={t('material.download.all')}
                        isLoading={isLoading}
                    />
                </Header>
                {isOpen &&
                    materials?.map((material, index) => (
                        <Material
                            onClick={() =>
                                handleDownloadClick(material._key, material.filename, false, material.mime_type)
                            }
                            key={index}
                        >
                            <FileIcon name={Icons.File} />
                            <Name>
                                {t('material.label')}: {material.name}
                            </Name>
                        </Material>
                    ))}
            </Materials>
        </Wrapper>
    );
};

export default MyMaterialsTemplate;
